//Import React Library
import React from 'react';

//Import helper for theme selection
import { Helmet } from "react-helmet"

//Import state management provider
import { useMyContext } from "./Context/context"


//This function loads theme, firebase auth mfe and lexis ui library mfe
function App() {
  //Load states from provider
  const { theme } = useMyContext();
  console.log("theme", theme)
  return (
    <div className="App">
      <Helmet>
        <body className={localStorage.getItem("theme") !== null ? localStorage.getItem("theme") : theme} />
      </Helmet>
      <div id="single-spa-application:firebase-auth"></div>
      <div id="single-spa-application:ui-library"></div>
    </div>
  );
}

export default App;

import React, { useState, useContext } from 'react'

// make a new context
export const MyContext = React.createContext();
export const useMyContext = () => useContext(MyContext);

// create the provider
export const MyProvider = (props) => {
  //Define state
  const [menuOpenState, setMenuOpenState] = useState(false)  
  const [isDarkModeEnabled, setDarkMode] = useState(false)
  const [theme, setTheme] = useState("light")

  // Funtion to update theme (light or dark)
  const updateThemeMode = (state) => {
    console.log("is dark mode enable :", state)
    //Update theme state
    setDarkMode(state);
    if (state === true) {
      setTheme("dark")
      console.log("setting to dark")
      localStorage.setItem("theme", "dark")
    }
    else {
      setTheme("light")
      console.log("setting to light")
      localStorage.setItem("theme", "light")
    }
  }

  



  return (
    <MyContext.Provider value={{
      isMenuOpen: menuOpenState,      
      theme,
      isDarkModeEnabled,      
      updateThemeMode,         
      toggleMenu: () => setMenuOpenState(!menuOpenState),
      stateChangeHandler: (newState) => setMenuOpenState(newState.isOpen)
    }}>
      {props.children}
    </MyContext.Provider>
  )
}



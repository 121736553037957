//Import React and React-dom libraries
import React from 'react';
import ReactDOM from 'react-dom';

//Import styles
import './index.css';
import 'bootstrap/dist/css/bootstrap.css';

//Import App Component
import App from './App';

//Import performance measuring tool
import reportWebVitals from './reportWebVitals';

//Import single spa functions
import { registerApplication, start } from "single-spa";

//Import state management provider
import { MyProvider } from "./Context/context"

//Render components
ReactDOM.render(

  <MyProvider>
    <App />
  </MyProvider>
  ,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();



//Register Lexis UI Micro frontend Library
registerApplication({
  name: "ui-library",
  app: () => window.System.import("@lexis/ui-library"),
  activeWhen: ["/"],
  //customProps: { product: "LHL-Admin", layouts: ["@lexis/admin-navbar"] }
});

//Register firebase auth micro front-end
window.System.import("@lexis/ui-library").then(() => {
  console.log("imported ui library")
  registerApplication({
    name: "firebase-auth",
    app: () => window.System.import("@lexis/firebase-auth"),
    activeWhen: ["/"],
    //customProps: { product: "LHL-Admin", layouts: ["@lexis/admin-navbar"] }
  });
})


//Load Api component
window.System.import("@lexis/admin-api").then((ers) => {
  //console.log(ers.publicApiFunction())


})


//Load styles component
window.System.import("@lexis/admin-styles").then(() => {
  // Activate the layout engine once the styleguide CSS is loaded  
  start();
});
